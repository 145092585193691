import React from "react";

import {StoryCard} from "./StoryCard"

export function CardBuilder(props) {
    const storyCardArray = []

    const parseStoryData = (StoriesPerSeason, createdStories) => {
        const storyDataArray = []
        var x = 0
        while( x < createdStories ){
            storyDataArray.push(props._tokenData.detailTokenData[x])

            storyCardArray.push(
                <StoryCard
                    key={x}
                    _tID={x+1}
                    _published={true}
                    _StoryData = {props._tokenData.detailTokenData[x]}
                    _purchaseToken={props._purchaseToken}
                ></StoryCard>
            )
            
            x++
        }
        var dummyData = {
            description: "Coming Soon",
            image: "???",
            name: "FutureStory",
        }
        while( x < StoriesPerSeason) {
            storyDataArray.push(dummyData)

            storyCardArray.push(
                <StoryCard
                    key={x}
                    _tID={x+1}
                    _published={false}
                    _StoryData = {dummyData}
                    _purchaseToken={props._purchaseToken}
                ></StoryCard>
            )
            x++
        }
        return storyDataArray
    }

    const StoryData = parseStoryData(10, 2) //todo add real variables (stoirse per season and created stories shoudl be top level)
    // construct list of individual story cards

    console.log('Card Builder Token Data ', props._tokenData)
    console.log('constructed data ', StoryData)
    
    return ( 
        <div style={{ 
            display: 'flex', 
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            }}>{ storyCardArray }</div> 
    )
}