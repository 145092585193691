import React from "react";

var openseaLogo = require("../assets/OpenSea-Logomark-Blue.svg")

export function StoryCard(props) {
    console.log("story card ", props)
    const StoryImageLogic = () => {
        if(props._published) {
            return (
                <img 
                style={{
                    height: '100%',
                    width: '100%',
                    cursor: 'pointer'
                }}
                src= { props._StoryData.tokenData.image }
                onClick={()=> window.open(props._StoryData.tokenData.image, "_blank")}
                alt= {'token image'} ></img>
            )
        }
        return (
            <div style={{
                height: '13em',
                width: '13.5em',
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto'
            }}>
                <div style={{
                    marginTop: 'auto',
                    marginBottom: 'auto'
                }}>coming soon</div>
            </div>
        )

    }
    const TokenSupplyText = () => {
        if (props._published ) {
            if (props._StoryData.tknLeft > 0) {
                return props._StoryData.tknLeft + "/" + props._StoryData.maxSupply + " remaining"
            }
            return "SOLD OUT"
        }
        return " "        
    }
    const StoryDescription = () => {
        if(props._published ){
            var attr = props._StoryData.tokenData.attributes
            console.log('story description ', props._StoryData)
            return attr[0].trait_type + ' ' + attr[0].value + ' ' + attr[1].trait_type + ' ' + attr[1].value
        }
        return "Season 1 Issue " + props._tID
    }
    const mintButtonConstructor = () => {
        if(props._published && props._StoryData.tknLeft === 0) {
            return (
                <a style={{}} href='https://opensea.io/collection/the-catnip-news' target="_blank">
                    <img style={{ height: '2em' }} src={openseaLogo} alt={'opensea logo'}></img>
                </a>
            )
        }

        if(props._published){
            return (
                <button
                className="btn btn-warning"
                type="button"
                onClick={ () => { props._purchaseToken(props._tID-1) } }
                >{ "mint" }</button>
            )
        } 
        return (
            <button
            className="btn "
            style={{
                color: 'white',
                background: 'black',
                cursor: 'not-allowed'
            }}
            type="button"
            disabled={true}
            >{ "🔒" }</button>
        )
    }

  return (      
    <div className="story-card-container " 
        role="status"
        style={{
            width: '19%',
            marginTop: '1em',
            marginBottom: '1em',
            marginLeft: '.3em',
            border: 'solid #000',
            borderWidth: '2px',
            marginRight: '.3em',
            minWidth: '15em',
            minHeight: '17em'
        }}>
        <div className="story-card-header"
            style={{
                marginTop: '1%',
                marginLeft: '3%'
            }}>
            { StoryDescription()  }
        </div>
        <div className="story-image"
            style={{
                display: 'flex',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '95%',
                height: '75%',
                border: '2px solid',
                color: 'black'
            }}>
        { StoryImageLogic() }
        </div>
        <div className="story-action-bar-container"
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '1%'
            }}>
            <div className="token-supply-container"
                style={{
                    marginLeft: '3%'
                }}>
                { TokenSupplyText() }
            </div>
            <div className="story-buttons"
                style={{
                    marginLeft: 'auto',
                    marginRight: '3%'
                }}>
                { mintButtonConstructor() }
            </div>
        </div>
        
    </div>
  );
}
