import React from "react";

export function Loading() {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 2,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.5)",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 3,
          top: "50%",
          left: "40%",
          right: "40%",
          width: "300px",
          height: "50px",
          marginLeft: "-50px",
          marginTop: " -25px",
          textAlign: "center",
        }}
      >
        <div className="no-wallet-text">
          Connecting your wallet ... 
        </div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>

      </div>
    </div>
  );
}
