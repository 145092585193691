import React, { useState, useEffect } from "react";

// We import the contract's artifacts and address here, as we are going to be
// using them with ethers
import TokenArtifact from "../contracts/cat-nip-news.json";
import contractAddress from "../contracts/contract-address.json";

import { NoWalletDetected } from "./NoWalletDetected";
import { Loading } from "./Loading";
import { CardBuilder } from "./CardBuilder"
import { NetworkErrorMessage } from "./NetworkErrorMessage";
import { WalletButtonConstructor } from "./WalletButtonConstructor";
import WebFont from 'webfontloader';



var headerImage = require("../assets/catnipnews-header.svg")
var twitterLogo = require("../assets/twitter-logo-blue.svg")
var discordLogo = require("../assets/Discord-Logo-Color.svg")
var openseaLogo = require("../assets/OpenSea-Logomark-Blue.svg")
var mooncatTeamImage = require("../assets/mooncatreporter.png")

// hardhat config network id, reference  https://docs.metamask.io/guide/ethereum-provider.html#properties
const HARDHAT_NETWORK_ID = '1';
// This is an error code that indicates that the user canceled a transaction
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;
// use ethers to interact with contract
const { ethers } = require("ethers");
const axios = require('axios');

const connectWallet = async() => {
    await _provider.send("eth_requestAccounts", []);
    const signer = _provider.getSigner();
    const connectedWalletAddress = await signer.getAddress()
    console.log("Account:", connectedWalletAddress);
    return connectedWalletAddress

}

const getWalletAddress = async() => {
    const selectedAddress = await window.ethereum.request({ method: 'eth_requestAccounts' });
    console.log("selected address ", selectedAddress)
    return selectedAddress
}
let  _provider = null;
let  _token = null;

//styles
const mainAppContainerStyle = {
    background: "rgba(255, 255, 255, 0.5)",
    alignContent: "center",
    marginLeft: "5em",
    marginRight: "5em",
    marginTop: "5em",
    marginBottom:"5em"
}
const mainAppContainerMobileStyle = {
    background: "rgba(255, 255, 255, 0.5)",
    alignContent: "center",
}
const mainAppStylingContainerStyle = {
    border: "2px solid #1d2124",
    boxShadow: "12px 12px 2px 1px #212529",
    padding:"1em"
}
const mainAppStylingMobileContainerStyle = {
    border: "2px solid #1d2124",
    boxShadow: "12px 12px 2px 1px #212529",
    padding:"5px"
}
const headerImageStyle = {
    display: "block",
    width: '100%',
    minWidth: '300px',
    marginLeft: "auto",
    marginRight: "auto" 
}
const headerFunctionalityContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '1%',
    marginRight: '1%'

}
const socialButtonImgStyle = { 
    height: '2em', 
    margin: '0em .5em'
}
const whatIsItContainerStyle = { 
    margin: '1% 1% 1% 1%' 
}
const whatIsItTitleStyle = { 
    fontFamily: 'Quantico', 
    fontWeight: '700', 
    fontSize: '30px' 
}
const whatIsIfParagraphStyle = { 
    fontFamily: 'Quantico', 
    fontSize: '18px' 
}


export function MainApp()  {
    const [networkError, setNetworkError] = useState(undefined)
    const [tokenData, setTokenData] = useState(undefined);
    const [balance, setBalance] = useState(undefined);
    const [selectedAddress, setSelectedAddress] = useState(undefined);

    useEffect(() => {
        //load fonts 
        WebFont.load({
            google: {
                families: ['Quantico:400,700', 'sans-serif']
            }
        });

        if(window.ethereum === undefined) {
            return 
        } 

        // load web3 provider
        // set up provider
        _provider = new ethers.providers.Web3Provider(window.ethereum);
        // set up contract
        _token = new ethers.Contract(
            contractAddress.Token,
            TokenArtifact.abi,
            _provider.getSigner(0)
        );
        //check if wallet is connected 
        // if it is check whether token data is loaded 
        // if not ask to connect 
        const checkIsWalletConnected = async() => {
            const connectedWallet = await window.ethereum.request({ method: 'eth_accounts' });
            if(connectedWallet.length >= 1){
                console.log('000', connectedWallet[0])
                if(!tokenData){
                    console.log('111')
                    await _getTokenData()
                    setSelectedAddress(connectedWallet[0])
                }
                return true 
            }
            console.log('222')
            await getWalletAddress()
            //once connected refresh the page
            window.location.reload(false);
            return false
        }

        checkIsWalletConnected()
  
        return
    });

    const clearState = () => {
        setNetworkError(undefined)
        setTokenData(undefined)
        setBalance(undefined)
        setSelectedAddress(undefined)
    }

    const loadWeb3 = async() => {
        
        const _address = await connectWallet()

        if (!_checkNetwork()) {
            return;
        }
        setSelectedAddress(_address)

        //reinitialize it whenever the user changes their account.
        window.ethereum.on("accountsChanged", ([newAddress]) => {
        // `accountsChanged` event can be triggered with an undefined newAddress.
        // This happens when the user removes the Dapp from the "Connected
            if (newAddress === undefined) {
            return clearState();
            }
            
            _getTokenData()
        });

        //reset component if the chain changed
        window.ethereum.on("chainChanged", ([networkId]) => {
            clearState();
            _getTokenData()
        });



    }
    // This method checks if Metamask selected network is Localhost:8545 
    const _checkNetwork = () => {
        if (window.ethereum.networkVersion === HARDHAT_NETWORK_ID) {
            console.log("eth network version ", window.ethereum.networkVersion);
            console.log("HARDHAT NETWOTK ID ", HARDHAT_NETWORK_ID);

            return true;
        }

        setNetworkError('Please connect Metamask to ',HARDHAT_NETWORK_ID )
        return false;
    }   
    
    const _getTokenData = async () => {
        console.log("Token Data ", _token)

        const name = await _token.name();
        const symbol = await _token.symbol();
        const CurrentTokenID = await _token.getCurrentTokenID()//.toNumber()
        var uniqueTokenNumber = CurrentTokenID.toNumber()
        
        var currentTokenData
        var singleTokenData
        var maxTokenSupply 
        var tokensLeft
        const detailTokenData = []
        for(var x = 0; x < uniqueTokenNumber; x++){
            currentTokenData = await _token.uri(x)
            maxTokenSupply = await _token.getMaxAmountOfCopies(x)
            tokensLeft = await _token.getNumeberOfCopiesLeft(x)
            singleTokenData = await axios.get(currentTokenData)
            detailTokenData.push({
                tokenData: singleTokenData.data,
                maxSupply: maxTokenSupply.toNumber(),
                tknLeft: tokensLeft.toNumber()
            })
        }
        setTokenData({ 
            name, 
            symbol,
            uniqueTokenNumber,
            detailTokenData
        })


        // get IPFS client to download meta data json
        //console.log("ipfs ", singleTokenData.data)

    }
    
    const _purchaseToken = async(tokenId) => {
        let overrides = {
            value: ethers.utils.parseEther(".05"),
            gasLimit: 500000,//200000,
        };
        console.log("purchase contract  ", _token.estimateGas)
        console.log('ether utils', ethers.utils)

        const purchaseTx = await _token.purchase(selectedAddress, tokenId, 1, '0x00', overrides)
        console.log('purchse tx ', purchaseTx)
        
    }
    // This is an utility method that turns an RPC error into a human readable
    // message.
    const _getRpcErrorMessage = (error) => {
        if (error.data) {
        return error.data.message;
        }

        return error.message;
    }
    //todo - remove 
    const _updateBalance = async (address) => {
        console.log("udpate balance address ", address)
        console.log("update balance token ", _token)
        const _balance = await _token.balanceOf(address, 1);
    
        setBalance(_balance)
    }

    const _dismissNetworkError = () => {
        setNetworkError(undefined)
    }

    const networkErrorConstructor = () => {
        if(networkError){
            return (
                <NetworkErrorMessage 
                message={networkError} 
                dismiss={_dismissNetworkError}/>
            )
        }
        return
    }

    const storyContentRenderingLogic = () => {
        //if no window ethereum
        
        if (window.ethereum === undefined) {
            return <NoWalletDetected />;
        }

        //if wallet not connected
        // ethereum.request({ method: 'eth_requestAccounts' })
        //if no wallet connected
        if (!tokenData) { //  || !this.state.balance
            return <Loading />;
        }

        //render story content 
        return (
            <CardBuilder
                _purchaseToken={_purchaseToken}
                _tokenData={tokenData}
                >
            </CardBuilder>
        )

    }
    //

    // render logic
    return (
        <div className="main-app-container" style={ (window.innerWidth < 800) ? mainAppContainerMobileStyle : mainAppContainerStyle}>
            <div className="main-app-styling-container" style={ (window.innerWidth < 800) ? mainAppStylingMobileContainerStyle : mainAppStylingContainerStyle}>
            <div className="header-image-container">
                <img src={headerImage} style={headerImageStyle}
                    alt={'header image'}></img>
            </div>

            <div className="header-functionality-container"
                style={headerFunctionalityContainerStyle}>
                <div className="header-button-container">
                    <WalletButtonConstructor
                    _selectedAddress={selectedAddress}
                    _loadWeb3={loadWeb3}
                    ></WalletButtonConstructor>
                    { networkErrorConstructor() }
                </div>
                <div className="social-button-container">
                    <a href='https://opensea.io/collection/the-catnip-news' target="_blank" rel="noopener noreferrer">
                        <img style={socialButtonImgStyle} src={openseaLogo} alt={'opensea logo'}></img>
                    </a>
                    <a href='https://twitter.com/thecatnipnews' target="_blank" rel="noopener noreferrer">
                        <img style={socialButtonImgStyle} src={twitterLogo} alt={'twitter logo'}></img>
                    </a>
                    <a href='https://discord.gg/2srfq7TGyx' target="_blank" rel="noopener noreferrer">
                        <img style={socialButtonImgStyle} src={discordLogo} alt={'discord logo'}></img>
                    </a>
                </div>
            </div>

            <div className="story-display-container">
                {storyContentRenderingLogic()}
            </div>


            <div style={whatIsItContainerStyle}className="whatisit-container">
                <div style={whatIsItTitleStyle} >What is it? </div>
                <div style={whatIsIfParagraphStyle} >
                    <p>
                    Reporting live from the MoonCat universe! After the MoonCats were adopted, we wondered – what happened to them? 
                    </p>
                    <p>
                    As Mooncat owners (#16536 and #20477), we love that there are 25k adorable Mooncats. But we wanted to know more about where they come from, who they are, and what they're up to today.
                    </p>
                    <p>
                    We will be reporting Mooncat missions and news, in space, from the newly-formed publication The Catnip News (The CN).
                    </p>
                    <p>
                    We cover news across the galaxy and metaverse, for now including Mooncats and missions – and potentially in the future other characters as well.
                    </p>
                </div>
            </div>
            
            <div style={{ margin: '1% 1%'}} className="team-container">
                <div style={{ fontFamily: 'Quantico', fontWeight: '700', fontSize: '30px' }} >Reporters </div>

                <div style={{ fontFamily: 'Quantico', fontSize:'18px' }} >
                    <p>
                    Our esteemed reporting staff includes Editor-in-chief Suzy Supernova <a href='https://twitter.com/degentrix' target='_blank'>@degentrix</a>, and Copywriter Ser Paws Nebula. We have a big heart for fellow MoonCats, artists of all kinds, and cheese (as all good MoonCats do).
                    </p>
                </div>
                <div style={{ display: 'flex' }}className='team-image'>
                    <img style={{ margin: 'auto', height: '10em' }}src={mooncatTeamImage} alt={'team image'}></img>
                </div>
                
            </div>

            <div style={{ margin: '1% 1%'}} className="faq-container">
                <div style={{ fontFamily: 'Quantico', fontWeight: '700', fontSize: '30px' }} >FAQ</div>
                <div style={{ fontFamily: 'Quantico', fontSize:'18px' }} >
                    <li  style={{ margin: '1%' }}><b>Who’s the writer?</b> Suzy is our writer, but she does take feedback and in the future we might take suggestions or even offer NFTs to purchase that get you a bespoke story from Suzy herself!</li>
                    <li style={{ margin: '1%' }} ><b>Is this affiliated with ponderware?</b> No, but we talk to them and are grateful to them for figuring out the initial direction of MoonCats, and being open source to allow cool projects to flourish - like maybe this one!</li>
                    <li style={{ margin: '1%' }}><b>Can I write for you? With you?</b> We’d love to chat! Hit us up on <a href='https://discord.gg/2srfq7TGyx'>Discord</a></li>
                    <li style={{ margin: '1%' }} ><b>What’s the roadmap?</b> We’re thinking eventually 550 stories; 10 x 1st, 20 x 2nd….90 x 9th, 100 x 10th. The first stories will be the rarest. </li>
                    <li style={{ margin: '1%' }}><b>Who are you?</b> We just love projects with stories and communities, and strive to add our creativity to projects we believe in.</li>
                </div>
            </div>
        </div>
        </div>
    )
}