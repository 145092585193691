
import React from "react";


export function WalletButtonConstructor(props) {
    console.log("walletButtonConstructor", props ) 
    if (!props._selectedAddress) {
    return (
        <button
        className="btn btn-warning"
        type="button"
        onClick={() => { props._loadWeb3()} }
        > Connect Wallet </button>
        )
    }
    var tempAdr = props._selectedAddress
    var shortAddr = tempAdr.substr(tempAdr.length - 5)
    return (
        <button
        className="btn btn-warning"
        type="button"
        disabled={true}
      > { "..." + shortAddr } </button>
    )
}